import { Box, styled } from "@mui/material";


export const Circle = styled(Box)(({
  background = '#fff',
  ...other
}) => ({
  position: 'absolute',
  background,
  ...other
}));