import { Box, Container, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import { ApplicationContext } from "src/context";
import { getCourseById, getJobById, paymentForPost } from "src/context/APIRequest";
import LoadingBox from "src/layouts/LoadingBox";
import Page from "src/layouts/Page";
const item = {
  title: 'Experts',
  color: 'info',
  price: 165,
  time: 'per Each Ad',
  priceId: 'experts',
  features: [
    'Experts write your ad',
    'Your job ad emailed to relevant candidates',
    'Priority placement in relevant search results',
    'Your job ad is featured in relevant search results',
    'Get Candidates faster',
    'Guaranteed success or your money back',
  ]
};
export default function PayForPost() {
  const { params } = useMatch(`/pay/:type/:id`);
  const { user, callAPI, setAlert, paymentTesting } = useContext(ApplicationContext);
  const {
    color, title, price, description, features = [], time = 0
  } = item;
  const [loading, setLoading] = useState(false);
  const callAPIRef = useRef(callAPI);
  const [post, setPost] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const [result, error] = await callAPIRef.current(params.type === 'job' ? getJobById : getCourseById, params);
      if (!error)
        setPost(result);
    }
    fetchData();
  }, [params]);


  return (<Page title='Payment Success'>
    <Container maxWidth='lg'>
      <Box sx={{
        minHeight: theme => `calc(100vh - ${(theme.mixins.toolbar.minHeight) * 2}px - ${theme.spacing(4)} - ${theme.spacing(4)})`,
        width: '100%',
        my: 4,
        display: 'grid',
        placeItems: 'center'
      }}><Paper component={Stack} key={color} sx={{
        position: 'relative',
        border: 'none',
        borderRadius: 0,
        flexGrow: 1,
        minHeight: '100%',
        width: '100%',
      }}>
          <LinearProgress color={color} variant='determinate' value={100} sx={{
            height: 8
          }} />
          {
            user.id !== post?.companyId ? <>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant='caption' textAlign={'center'}>You are not authorized to pay for this post.</Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant='caption' textAlign={'center'} gutterBottom >You can close this browser and open app</Typography>
            </> :
              post?.paymentStatus === 'paid' ? < >
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant='caption' textAlign={'center'} gutterBottom >
                  Payment successfully for this post.
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant='caption' textAlign={'center'} gutterBottom >You can close this browser and open app</Typography>
              </ > :
                <>
                  <Stack px={2} py={1} spacing={3} flexGrow={1}>
                    <Typography variant='body2'>{title}</Typography>
                    <Stack>
                      <Typography variant='caption' textAlign={'center'} fontSize={24}>${price} {price ? '+GST' : ''}</Typography>
                      <Typography variant='body2' textAlign={'center'} fontSize={24}>{time ? time : ''}</Typography>
                    </Stack>
                    <Typography variant='caption'>{description}</Typography>
                    <Stack pb={3} component='ul'>
                      {features.map((feature, index) => <Typography key={`${title}~${index}`} component='li' variant='body2' ml={2} textAlign={'start'}>{feature}</Typography>)}
                    </Stack>
                  </Stack>

                  <Typography variant='caption' textAlign={'center'}>* non-refundable</Typography>
                  <Box sx={{ mt: 2, mx: 3, }}>
                    <PayPalScriptProvider options={{
                      vault: true,
                      currency: 'AUD',
                      clientId: paymentTesting ?
                        "ASe7CwcXeU3mq6Rui28N6wqIegyj4ZWZDkvKDzVOVN5rWUSse2Uwhnbs13ufB-Ep7_APmbZ16d49r_5n" :
                        "AUacpFUc_BvZCjxhYi-8c96EIcyrgCLDrwF7hJbfx0-UEV-x_IOkQzU5O330dGmpq5jrQjYfWXt30sRw",
                    }}>
                      <PayPalButtons style={{ layout: "horizontal", color: 'blue', label: 'paypal', tagline: false, }}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [{
                              "custom_id": `job-${params.id}`,
                              "amount": { "currency_code": "AUD", "value": 181.5, "breakdown": { "item_total": { "currency_code": "AUD", "value": 165 }, "shipping": { "currency_code": "AUD", "value": 0 }, "tax_total": { "currency_code": "AUD", "value": 16.5 } } }
                            }]
                          });
                        }}

                        onApprove={async (data, actions) => {
                          setLoading(true)
                          const details = await actions.order.capture();
                          if (details.status === 'COMPLETED') {
                            const [data, error] = await callAPI(paymentForPost, { ...params, orderId: details.id })
                            if (!error) {
                              setPost(data);
                              setAlert({ open: true, type: 'success', message: "Payment Sucessfully." });
                              setLoading(false)
                            } else {
                              setAlert({ open: true, type: 'success', message: "Payment Failed." });
                              setLoading(false)
                            }
                            return
                          }
                          setAlert({ open: true, type: 'success', message: "Payment Failed." });

                        }} />
                    </PayPalScriptProvider>
                  </Box>
                  {loading && <LoadingBox position='absolute' />}
                </>}
        </Paper>
      </Box>
    </Container>
  </Page>
  );
}

