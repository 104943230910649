import { createContext, useEffect, useRef, useState } from 'react';
import { getUser, loginAPI, updatePersonalDetail } from './APIRequest';
export const ApplicationContext = createContext(null);
const ApplicationProvider = ({ children }) => {

  // get parameter from url
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('flutter')) {
    const token = urlParams.get('token');
    localStorage.setItem('token', token);

    const flutter = urlParams.get('flutter');
    localStorage.setItem('flutter', flutter);

    const paymentTesting = urlParams.get('paymentTesting');
    localStorage.setItem('paymentTesting', paymentTesting);

    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);
  }
  const flutter = localStorage.getItem('flutter');
  const paymentTesting = Boolean("true" === localStorage.getItem('paymentTesting'));


  const isJob = localStorage.getItem('isJob');
  const [employer, addEmployer] = useState(isJob ? isJob === 'true' : undefined);
  const setEmployer = (value) => {
    if (value === undefined) localStorage.removeItem('isJob');
    else localStorage.setItem('isJob', value);
    addEmployer(value);
  }
  const [size, setSize] = useState('small');
  const [auth, setAuth] = useState(!!localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || "{}"));
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);

  const handelError = (result, status) => {
    if (status === 403) {
      logout(false);
    }
    setAlert({ open: true, type: 'error', message: result.data })
  }

  const callAPI = async (API, data, status = [422, 404]) => {
    const error = [null, 'Network Error!!!'];
    try {
      const fetchResult = await API(data);
      const result = await fetchResult.json();
      if (fetchResult.ok) {
        error[0] = result.data;
        delete error[1]
      } else if (!status.includes(fetchResult.status)) {
        handelError(result, fetchResult.status);
      } else {
        error[1] = result.data;
      }
    } catch (error) {
      handelError({ data: 'Network Error!!!' });
    }
    return error;

  }
  const login = async (data, fun = loginAPI) => {
    const [result, error] = await callAPI(fun, data, [422, 404]);
    if (result) {
      const user = result.user;
      if (user.userRole !== 'ROLE_EMPLOYER') {
        setAlert({ open: true, type: 'error', message: "Your are not a employer " })
        return;
      }
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', result.authToken);
      setAuth(result.authToken);
    }
    return [result, error];
  };

  const logout = (action = true) => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    if (action) setEmployer(undefined);
    window.recaptchaVerifier = window.confirmationResult = null;
    setAuth(null);
  }
  const callAPIRef = useRef(callAPI);
  const logoutRef = useRef(logout);
  const updateProfile = async (data) => {
    const [result, error] = await callAPI(updatePersonalDetail, data, [422, 404]);
    if (!error) {
      setUser(result);
      setAlert({ open: true, type: 'success', message: "Updated successfully" })
    } else {
      setAlert({ open: true, type: 'error', message: "error" })
    }
  }

  const fetchUser = async () => {
    const [result] = await callAPIRef.current(getUser);
    if (result.userRole !== 'ROLE_EMPLOYER') {
      setAlert({ open: true, type: 'error', message: "Your are not a employer " })
      logoutRef.current()
      return;
    }
    localStorage.setItem('user', JSON.stringify(result));
    setUser(result);
  }
  useEffect(() => {
    if (auth) {
      fetchUser();
    }
  }, [auth])

  return (
    <ApplicationContext.Provider value={{
      auth,
      user, updateProfile,
      alert,
      login,
      logout,
      setAlert,
      callAPI,
      size, setSize,
      loading, setLoading,
      employer, setEmployer,
      fetchUser, setUser,
      flutter,
      paymentTesting,
    }}>{children}</ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
