// material
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, small, ...props }) {
  const theme = useTheme()
  return <Box component="img" src={small ? '/logo.svg' : theme.palette.mode === 'dark' ? '/logo-full-white.svg' : '/logo-full.svg'} sx={{ height: 40, objectFit: 'contain', ...sx }} {...props} />;
}
