import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
const LoadingBox = ({ position = 'fixed' }) => {
  return (
    <Box sx={{ display: 'grid', height: position === 'fixed' ? '100vh' : '100%', position, top: 0, left: 0, bottom: 0, right: 0, zIndex: 9999 }}>
      <CircularProgress sx={{ placeSelf: 'center' }} />
    </Box>
  )
}
LoadingBox.prototype = {
  position: PropTypes.oneOf(['fixed', 'absolute', 'relative']),
}

export default LoadingBox;