import { useContext, useMemo } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { ApplicationContext } from "src/context";
import Page from "src/layouts/Page";
import PlanItem from "./PlanItem";
import { subPlans, } from "./plans";

const Pay = () => {
  const { params } = useMatch('/plans/:planId')
  const { planId } = params || {};
  const { user, paymentTesting } = useContext(ApplicationContext);
  const adType = useMemo(() => {
    return subPlans(paymentTesting).find(adType => adType.priceId === planId) || {}
  }, [planId, paymentTesting])
  if (user.paypalSubscription) {
    return <Navigate to={'/success'} />;
  }


  const { title } = adType;

  return (
    <Page title={`${title} Subscribe `} sx={{
      height: theme => `calc(100vh - ${(theme.mixins.toolbar.minHeight) * 2}px - ${theme.spacing(4)} - ${theme.spacing(4)})`,
    }}>
      <PlanItem item={adType} payment />
    </Page>
  )
}

export default Pay;