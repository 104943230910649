import { get, post, put, remove } from "./APIBase";
import {
  APPLICANTS_URL, AUTH_URL, CAPTCHA_URL, CHECK_URL, FORGOT_PASSOWRD_URL, GET_USER, JOB_URL, LOCATION_URL, NOTIFICATION_URL,
  REGISTER_URL, RESET_PASSWORD_URL, SKILL_URL, STRIPE_URL, SUBSCRIPTION_URL, USER_URL, VERIFY_CODE_URL,
  COURSE_URL,
  EMPLOYER_URL
} from "./APIInterface";

export const loginAPI = async (data) => {
  return await post(AUTH_URL, data);
}
export const socialLogin = async ({ provider, accessToken }) => {
  return await get(AUTH_URL + '/social/' + provider, { accessToken });
}

export const getUser = async () => {
  return await get(AUTH_URL);
}

export const getUserById = async (userId) => {
  return await get(GET_USER(userId));
}

export const changePassword = async (data) => {
  return await put(`${USER_URL}change-password`, data);
}

export const changeEmail = async (data) => {
  return await put(`${USER_URL}change-email`, data);
}

export const addMobileNumber = async (data) => {
  return await put(`${USER_URL}add-mobile`, data);
}

export const resendSms = async () => {
  return await put(`${USER_URL}resend-sms`);
}

export const sendVerificationEmail = async () => {
  return await put(`${USER_URL}send-email`);
}

export const verifyEmail = async ({ send = 'email', verificationCode }) => {
  return await get(`${USER_URL}verify-${send}/${verificationCode}`);
}

export const updatePersonalDetail = async (data) => {
  return await put(USER_URL + 'details', data);
}


export const captchaAPI = async () => {
  return await get(CAPTCHA_URL);
}
export const registerAPI = async (data) => {
  return await post(REGISTER_URL, data);
}
export const checkAPI = async (data) => {
  return await post(CHECK_URL, data);
}

export const forgotPassowrdAPI = async (data) => {
  return await post(FORGOT_PASSOWRD_URL, data);
}

export const verifyCodeAPI = async (data) => {
  return await post(VERIFY_CODE_URL, data);
}

export const resetPasswordAPI = async (data) => {
  return await post(RESET_PASSWORD_URL, data);
}

const nonCache = {
  'pragma': 'no-cache',
  'cache-control': 'no-cache'
};

// Job
export const createJob = async (data) => {
  if (data.id) {
    return await put(JOB_URL, data);
  }
  return await post(JOB_URL, data);
}
export const getAllJob = async () => {
  return await get(`${JOB_URL}/list`);
}

export const JobListAPI = async ({ page = 0, size = 10, sort = 'createdDate,desc', search }) => {
  return await get(`${JOB_URL}${search ? '/search' : ''}?${search ? `search=${search}&` : ''}page=${page}&size=${size}&sort=${sort}`);
}

export const getJobById = async (data) => {
  const { id, cache = true } = typeof data === 'object' ? data : { id: data };
  return await get(`${JOB_URL}/${id}`, cache ? {} : nonCache);
}

export const JobDeleteAPI = async (id) => await remove(`${JOB_URL}/${id}`)

export const updateJob = async ({ jobId, ...data }) => await post(`${JOB_URL}/update/${jobId}`, data)

export const paymentForPost = async ({ type, id, orderId }) => await post(`${EMPLOYER_URL}payment/${type}/${id}/${orderId}`, {})
// Location
export const getAllLocation = async () => await get(`${LOCATION_URL}/list`)

export const locationListAPI = async ({ page = 0, size = 20, sort = '', search }) => await get(`${LOCATION_URL}${search ? '/search' : ''}?${search ? `search=${search}&` : ''}page=${page}&size=${size}&sort=${sort}`)

export const getLocationById = async (data) => {
  const { id, cache = true } = typeof data === 'object' ? data : { id: data };
  return await get(`${LOCATION_URL}/${id}`, cache ? {} : nonCache);
}

export const locationDeleteAPI = async (id) => {
  return await remove(`${LOCATION_URL}/${id}`);
}



// Skill 
export const getAllSkill = async () => {
  return await get(`${SKILL_URL}/list`);
}

export const SkillListAPI = async ({ page, size, sort = '', search }) => {
  return await get(`${SKILL_URL}${search ? '/search' : ''}?search=${search ? `${search}` : ''}forJob,bool,true,and,&page=${page}&size=${size}&sort=${sort}`);
}

export const getSkillById = async (data) => {
  const { id, cache = true } = typeof data === 'object' ? data : { id: data };
  return await get(`${SKILL_URL}/${id}`, cache ? {} : nonCache);
}

export const SkillDeleteAPI = async (id) => {
  return await remove(`${SKILL_URL}/${id}`);
}

// Applicants
export const createApplicants = async ({ jobId, courseId, ...data }) => {
  return await post(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}`, data, { jobId, courseId });
}

export const updateApplicants = async ({ jobId, courseId, ...data }) => {
  return await put(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}`, data, { jobId, courseId });
}

export const getTotalProposals = async ({ jobId, courseId }) => {
  return await get(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}/count`, { jobId, courseId });
}

export const getAllApplicants = async ({ jobId, courseId }) => {
  return await get(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}/list`, { jobId, courseId });
}

export const applicantsListAPI = async ({ page, size, sort = 'createdDate,desc', search, jobId, courseId }) => {
  return await get(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}${search ? '/search' : ''}?${search ? `search=${search}&` : ''}page=${page}&size=${size}&sort=${sort}`, { jobId, courseId });
}

export const getApplicantsById = async ({ id, jobId, courseId, cache }) => {
  return await get(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}/${id}`, { ...(cache ? {} : nonCache), ...{ jobId, courseId } });
}

export const applicantsDeleteAPI = async ({ id, jobId, courseId }) => {
  return await remove(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}/${id}`, { jobId, courseId });
}

export const getResumeByJobApplicantId = async ({ id, jobId, courseId }) => {
  return await get(`${APPLICANTS_URL}${jobId ? 'job' : 'course'}/${id}/resume`, { jobId, courseId });
}

// Notification
export const getCustomer = async () => {
  return await get(`${STRIPE_URL}customer`);
}

export const subscription = async ({ planId, ...data }) => {
  return await post(`${STRIPE_URL}subscription/${planId}`, data);
}

export const getSubscription = async () => {
  return await get(`${SUBSCRIPTION_URL}`);
}
export const cancelSubscription = async () => {
  return await remove(`${SUBSCRIPTION_URL}`);
}

// Notification

export const markAllRead = async () => {
  return await get(`${NOTIFICATION_URL}/mark-all-as-read`);
}

export const createNotification = async (data) => {
  return await put(NOTIFICATION_URL, data);
}

export const getAllNotification = async () => {
  return await get(`${NOTIFICATION_URL}/list`);
}

export const notificationListAPI = async ({ page, size, sort = '', search }) => {
  return await get(`${NOTIFICATION_URL}${search ? '/search' : ''}?${search ? `search=${search}&` : ''}page=${page}&size=${size}&sort=${sort}`);
}

export const getNotificationById = async (data) => {
  const { id, cache = true } = typeof data === 'object' ? data : { id: data };
  return await get(`${NOTIFICATION_URL}/${id}`, cache ? {} : nonCache);
}

export const notificationDeleteAPI = async (id) => {
  return await remove(`${NOTIFICATION_URL}/${id}`);
}


export const paypalSubscription = async (data) => {
  return await post(`${SUBSCRIPTION_URL}/${data.id}`, data);
}


// Course
export const createCourse = async (data) => {
  if (data.id) {
    return await put(COURSE_URL, data);
  }
  return await post(COURSE_URL, data);
}
export const getAllCourse = async () => {
  return await get(`${COURSE_URL}/list`);
}

export const courseListAPI = async ({ page, size, sort = '', search }) => {
  return await get(`${COURSE_URL}${search ? '/search' : ''}?${search ? `search=${search}&` : ''}page=${page}&size=${size}&sort=${sort}`);
}

export const getCourseById = async (data) => {
  const { id, cache = true } = typeof data === 'object' ? data : { id: data };
  return await get(`${COURSE_URL}/${id}`, cache ? {} : nonCache);
}

export const courseDeleteAPI = async (id) => {
  return await remove(`${COURSE_URL}/${id}`);
}
