import { useContext, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ApplicationContext } from './context';
import LoginForm from './layouts/LoginForm';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Pay from './pages/Pay';
import PayForPost from './pages/PayForPost';
import Success from './pages/Success';
import Plans from './pages/plans';

export default function Router() {
  const { auth, employer } = useContext(ApplicationContext);
  const [routes, setRoutes] = useState({});
  useEffect(() => {
    setRoutes(auth ? {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Plans /> },
        { path: '/pay/:type/:id', element: <PayForPost /> },
        { path: '/success', element: <Success /> },
        { path: '/plans/:planId', element: <Pay /> },
      ]
    } : {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <LoginForm /> },
        { path: '*', element: <LoginForm /> },
      ]
    });
  }, [auth, employer])

  return useRoutes([routes]);
}
