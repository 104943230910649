import { alpha } from "@mui/material";
import { responsiveFontSizes } from "../typography";

export default function Button(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#DEDDDD',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeSmall: {
          padding: theme.spacing(.7, 2),
        },
        sizeMedium: {
          lineHeight: '21px',
          ...responsiveFontSizes({ sm: 12, md: 13, lg: 14 }),
          padding: theme.spacing(1, 2.5),
        },
        sizeLarge: {
          fontSize: 16,
          ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
          lineHeight: '24px',
          padding: theme.spacing(1.5, 3),
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        outlinedInherit: {
          // border: `1px solid ${theme.palette.grey[500_32]}`,
          border: 'none',
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
      },
      variants: [
        {
          props: {
            variant: "fill",
          },
          style: {
            lineHeight: '21px',
            color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.contrastText,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.3)
            }
          }
        }
      ]
    }
  };
}
