import { Box, Container } from "@mui/material";
import { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { ApplicationContext } from "src/context";
import Page from "src/layouts/Page";
import PlanItem from "./PlanItem";
import { subPlans } from "./plans";


const Success = () => {
  const { user: { paypalSubscription }, paymentTesting } = useContext(ApplicationContext);
  const adType = useMemo(() => {
    return subPlans(paymentTesting).find(adType => adType.priceId === paypalSubscription?.id) || {}
  }, [paypalSubscription, paymentTesting])
  if (!paypalSubscription) {
    return <Navigate to={'/'} />;
  }

  return <Page title='Payment Success'>
    <Container maxWidth='lg'>
      <Box sx={{
        minHeight: theme => `calc(100vh - ${(theme.mixins.toolbar.minHeight) * 2}px - ${theme.spacing(4)} - ${theme.spacing(4)})`,
        my: 4,
        display: 'grid',
        placeItems: 'center'
      }}>
        <PlanItem item={adType} ribbon />
      </Box>
    </Container>
  </Page>
}

export default Success