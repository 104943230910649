import { Box, Container } from "@mui/material";
import { useContext, useRef } from "react";
import { Navigate } from "react-router-dom";
import { ApplicationContext } from "src/context";
import Page from "src/layouts/Page";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import './style.css';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import PlanItem from "./PlanItem";
export const subPlans = (paymentTesting) => [
  {
    title: 'Classic',
    color: 'success',
    price: 287,
    priceId: !paymentTesting ? "P-9NF0460017944925AMLDGUUI" : 'P-2MH05760KP744664WMVXPS2A',
    time: '3 Months Membership',
    features: [
      'Find great candidates with our basic ad ',
      'Select Your job ad emailed to relevant candidates Featured ad',
    ]
  },
  {
    title: 'Standard',
    color: 'warning',
    price: 430,
    priceId: !paymentTesting ? 'P-97T02690TU705561CMLDGU5I' : 'P-3GY15247X7607800DMVXPTXQ',
    time: '6 Months Membership',
    features: [
      'Attract more candidates with a featured listing',
      'Selected Your job ad emailed to relevant candidates',
      'Priority listing in search results',
      'Priority listing in candidate emails',
      'Get candidates faster',
      'Guaranteed Hire',
    ]
  },
  {
    title: 'Premium',
    color: 'info',
    price: 860,
    priceId: !paymentTesting ? 'P-05N24114WC2941744MLDGVFY' : 'P-78A86850CF829162AMVXPUIY',
    time: '1 Year Membership',
    features: [
      'Your job ad emailed to relevant candidates',
      'Priority listing in search results',
      'Priority listing in candidate emails',
      'Get candidates faster',
      'Guaranteed Hire',
      'Guaranteed success or your money back',
    ]
  }
]
const Plans = () => {
  const { user: { paypalSubscription }, paymentTesting } = useContext(ApplicationContext);
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  }; if (paypalSubscription) {
    return <Navigate to={'/success'} />;
  }
  return (
    <Page title='Subscription Plans'>
      <Container maxWidth="md" sx={{ position: 'relative' }}>

        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
        <Container maxWidth="sm">
          <Box sx={{
            height: theme => `calc(100vh - ${(theme.mixins.toolbar.minHeight) * 2}px - ${theme.spacing(4)} - ${theme.spacing(4)})`,
            mt: 4
          }}
            component={Swiper}
            spaceBetween={30}
            centeredSlides={true}

            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="mySwiper"
          >
            {subPlans(paymentTesting).map((item, index) => <SwiperSlide key={item.priceId}><PlanItem item={item} index={index} /></SwiperSlide>)}

            <div className="autoplay-progress" slot="container-end">
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <span ref={progressContent}></span>
            </div>
          </Box>
        </Container>
      </Container>
    </Page >
  );
}

export default Plans;


