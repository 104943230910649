
export const localhost = false;
const live = process.env.NODE_ENV === 'production'
export const ORIGIN = live || !localhost ? 'https://api.studentsemploymentservices.com.au/' : 'http://surojd-new:8080/'
export const BASE_URL = `${ORIGIN}api/`;
export const AUTH_URL = BASE_URL + 'auth';
export const PUBLIC_URL = BASE_URL + 'public/';
export const CAPTCHA_URL = PUBLIC_URL + 'captcha';
export const USER_IMAGE_URL = (imageId = 0, v, original = false) => PUBLIC_URL + `image/profile-${imageId}.jpg?original=${original}&v=${v}`;
export const CAPTCHA_IMAGE_URL = (imageId) => PUBLIC_URL + `image/captcha-${imageId}.jpg`;
export const COURSE_IMAGE_URL = (imageId) => PUBLIC_URL + `image/course-${imageId}.jpg`;
export const RESUME_IMAGE_URL = (imageId) => PUBLIC_URL + `image/resume-${imageId}.jpg`;
export const REGISTER_URL = PUBLIC_URL + 'register';
export const CHECK_URL = PUBLIC_URL + 'check';
export const FORGOT_PASSOWRD_URL = PUBLIC_URL + 'forgot-password';
export const VERIFY_CODE_URL = PUBLIC_URL + 'verify-code';
export const RESET_PASSWORD_URL = PUBLIC_URL + 'reset-password';
export const GET_CONFIG_URL = PUBLIC_URL + 'config';

const SHOP_URL = BASE_URL + 'shop/';
export const USER_URL = BASE_URL + 'user/';
export const STRIPE_URL = BASE_URL + 'stripe/';
const ADMIN_URL = BASE_URL + 'admin/';
//user
export const CART_URL = SHOP_URL + 'cart';
export const ADDRESS_URL = USER_URL + 'address-book';
export const ORDER_URL = USER_URL + 'orders';

export const PAYMENT_URL = SHOP_URL + 'payment-options';
export const DELIVERY_URL = SHOP_URL + 'delivery-options';

export const COUNTRY_URL = ADMIN_URL + 'country'
export const ZONE_URL = ADMIN_URL + 'zone'
export const DISTRICT_URL = ADMIN_URL + 'district'


//Shop
export const FILES_URL = BASE_URL + 'shop/files'
export const USED_FILES_URL = BASE_URL + 'shop/files/used'

export const CONFIG_URL = SHOP_URL + 'config';
export const BANNER_URL = SHOP_URL + 'banner';
export const CATEGORY_URL = SHOP_URL + 'category';
export const BRAND_URL = PUBLIC_URL + 'brand';
export const PRODUCT_URL = SHOP_URL + 'product';



export const SKILL_URL = BASE_URL + 'skill';
export const JOB_URL = BASE_URL + 'job';
export const COURSE_URL = BASE_URL + 'course';
export const LOCATION_URL = PUBLIC_URL + 'locations';
export const EMPLOYER_URL = BASE_URL + 'employer/';
export const SUBSCRIPTION_URL = EMPLOYER_URL + 'subscription';

export const APPLICANTS_URL = EMPLOYER_URL + 'applicant/';

export const NOTIFICATION_URL = USER_URL + 'notificiation';
export const GET_USER = (userId) => PUBLIC_URL + 'user/' + userId