import { Alert, Snackbar } from '@mui/material';
import { Suspense, useContext } from 'react';
import { ApplicationContext } from './context';
import Router from './routes';
import ThemeConfig from './theme';
import LoadingBox from './layouts/LoadingBox';
import ScrollToTop from './layouts/ScrollToTop';

function App() {
  const context = useContext(ApplicationContext);
  const { alert = { open: false }, setAlert } = context;
  const { open, type, message, autoHideDuration = 3000 } = alert;
  const handleClose = _ => setAlert();
  return (
    <ThemeConfig>
      <ScrollToTop />
      <Snackbar open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={autoHideDuration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type}>{message}</Alert>
      </Snackbar>
      <Suspense fallback={<LoadingBox position='fixed' />}>
        <Router />
      </Suspense>
    </ThemeConfig>
  );
}

export default App;
