import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar, Box, CardActionArea,
  IconButton, InputAdornment, Paper, Stack,
  TextField, Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useContext, useState } from 'react';
import { ApplicationContext } from 'src/context';
import * as Yup from 'yup';
import ChooseForm from './ChooseForm';
import WrapperForm from './WrapperForm';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    username: Yup.string().email('Email Address Not valid').required('Email Address is required'),
    password: Yup.string().required('Password is required')
  });
  const { login, employer, setEmployer } = useContext(ApplicationContext);
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (data) => {
      const [result, error] = await login(data)
      if (!result) {
        setFieldError('username', error.data)
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldError } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  if (employer === undefined)
    return <ChooseForm />
  return (
    <WrapperForm page={'Login'}
      social={false} back={false}
      form={{
        title: employer ? 'Post a jobs and recruit employees.' :
          'Post courses and make connections with learners.'
        ,
        body: <Typography variant="subtitle1" color='primary'
        textAlign='center'
          sx={{ cursor: 'pointer' }}
          onClick={e => { setEmployer(undefined) }}
        >
          Not an {employer ? 'employer' : 'institute'}?
        </Typography>,
      }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              size='small'
              fullWidth
              autoComplete="username"
              label="Email"
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />

            <TextField
              fullWidth
              size='small'
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <LoadingButton
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Login
            </LoadingButton>
          </Stack>

        </Form>
      </FormikProvider>
    </WrapperForm>
  );
}

export function UserType({ src, title, ...props }) {
  return (<Paper variant='outlined'{...props}>
    <CardActionArea>
      <Stack direction={'row'}>
        <Avatar sx={{ margin: 1 }} src={src} alt={title} />
        <Box display='grid' alignItems='center'>
          <Typography variant='h6' sx={{ px: 2, py: 1 }}>
            {title}
          </Typography>
        </Box>
      </Stack>
    </CardActionArea>
  </Paper>);
}


