import { alpha } from "@mui/material";

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        outlined: {
          border: `1px solid ${alpha('#DEDDDD', theme.palette.mode === 'dark' ? 0.5 : 1)}`
        }
      },
      variants: [
        {
          props: {
            variant: "primary",
          },
          style: {
            backgroundColor: theme?.palette.primary.main
          }
        }
      ]
    }
  };
}
