// material
import { PowerSettingsNew } from '@mui/icons-material';
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Container, IconButton, Stack, Toolbar } from '@mui/material';
import { Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { ApplicationContext } from 'src/context';
import { CustomThemeContext } from 'src/theme';
import LoadingBox from './LoadingBox';
import Logo from './Logo';

const themes = [
  { label: 'Light', value: 'light' },
  { label: 'System', value: 'system' },
  { label: 'Dark', value: 'dark' },
];
export default function LogoOnlyLayout() {
  const { light, setLight } = useContext(CustomThemeContext);
  const { auth, logout, flutter } = useContext(ApplicationContext);
  const setTheme = e => {
    localStorage.setItem('userTheme', e);
    setLight(e);
  }
  return (
    <Stack sx={{ height: '100dvh' }}>
      {!flutter && <AppBar position='relative' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} >
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            <Box flexGrow={1} />
            <Logo />
            <Box flexGrow={1} />
            {auth && <IconButton title='Logout' onClick={e => logout()}>
              <PowerSettingsNew />
            </IconButton>}
          </Toolbar>
        </Container>
      </AppBar>}
      <Box flexGrow={1} display={'flex'} flexDirection={'column'} justifyContent='center' sx={{ position: 'relative' }} >
        <Container maxWidth={'sm'} >
          <Suspense fallback={<LoadingBox position='absolute' />}>
            <Outlet />
          </Suspense>
        </Container>
      </Box>
      {!flutter && <BottomNavigation
        showLabels
        value={light}
        onChange={(event, newValue) => setTheme(newValue)}
        sx={{
          bottom: 0,
          width: '100%',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        {
          themes.map(({ label, value }) => (<BottomNavigationAction {...{ label, value, key: value }} />))
        }

      </BottomNavigation>}
    </Stack>
  );
}
