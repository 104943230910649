import { Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationContext } from 'src/context';
import { Circle } from './Circle';
import Logo from './Logo';
import Page from './Page';

const HeaderStyle = styled('header')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 3, 0, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0, 0)
  },
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}));
export default function WrapperForm({ children, back = true, page = 'Title', form = {
  title: 'Title', body: 'body'
},

}) {
  const { auth, logout } = useContext(ApplicationContext);

  return (
    <Page title={page}  >

      <Circle {...{
        width: 250,
        height: 250,
        borderRadius: '50%',
        bottom: -50,
        left: -50,
        opacity: .1,
      }} />
      <Circle {...{
        width: 118,
        height: 118,
        borderRadius: '50%',
        bottom: 100,
        left: 100,
        opacity: .1,
      }} />
      <Container maxWidth="sm" sx={{ px: 5, mt: 10, mb: 5 }}>
        <HeaderStyle>
          <Link href="https://studentsemploymentservices.com.au/" >
            <Logo sx={{ height: 50 }} />
          </Link>
        </HeaderStyle>
        <Stack sx={{ my: 5 }} spacing={1}>
          <Typography variant="h4" fontSize={{ xs: 18, sm: 22, md: 28 }} textAlign='center' >
            {form.title}
          </Typography>
          {form.body}
        </Stack>
        {children}
        {!back && < Stack alignItems={'center'} mt={2}>

          {auth ? <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} onClick={logout}>
            Logout
          </Typography> :
            <>
              {back && <Typography variant="subtitle2" component={RouterLink} to="/">
                Back to Log In
              </Typography>}</>
          }
        </Stack>}
      </Container>
    </Page >
  );
}
