import {
  Stack
} from '@mui/material';
import { useContext } from 'react';
import { ApplicationContext } from 'src/context';
import { UserType } from './LoginForm';
import WrapperForm from './WrapperForm';

export default function ChooseForm({ register }) {
  const { setEmployer } = useContext(ApplicationContext);
  return (
    <WrapperForm page={`${register ? 'Register' : 'Login'} as `}
      back={false}
      form={{ title: 'Select your user type' }}>
      <Stack gap={2}>
        <UserType src={'/employer.svg'} title='Employers' onClick={e => { setEmployer(true); }} />
        <UserType src={'/student.svg'} title='Institutes' onClick={e => { setEmployer(false); }} />
      </Stack>
    </WrapperForm>
  );
}
