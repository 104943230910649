import { Box, Button, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApplicationContext } from "src/context";
import { cancelSubscription, paypalSubscription } from "src/context/APIRequest";
import 'src/pages/ribbon.css';

export default function PlanItem({ item, ribbon, payment }) {
  const navigate = useNavigate()
  const { user, fetchUser, callAPI, paymentTesting } = useContext(ApplicationContext);
  const { id = 'free' } = user.paypalSubscription || {};
  const {
    color, title, price, priceId, description, features = [], time = 0
  } = item;
  const onCancel = async () => {
    const [,] = await callAPI(cancelSubscription)
    await fetchUser()
    navigate("/")
  };
  // check Approval
  const onApprove = async (data, actions) => {
    debugger
    const [,] = await callAPI(paypalSubscription, { ...data, id: priceId })
    await fetchUser()
    try {
      window.postMessage('done', '*');
      window.parent?.postMessage('done', '*');
      window.close();
    } catch (error) {

    }
    navigate("/success")
    // window.postMessage({ type: 'paypal_subscription', data: { ...data, id: priceId } }, '*')
  };
  return (<Paper component={Stack} pb={3} key={color} sx={{
    position: 'relative',
    border: 'none',
    borderRadius: 0,
    flexGrow: 1,
    minHeight: '100%',
  }}>
    {ribbon && <div class="ribbon ribbon-top-right"><span>Subscribed</span></div>}
    <LinearProgress color={color} variant='determinate' value={100} sx={{
      height: 8
    }} />
    <Stack px={2} py={1} spacing={3} flexGrow={1}>
      <Typography variant='body2'>{title}</Typography>
      <Stack>
        <Typography variant='caption' textAlign={'center'} fontSize={24}>${price} {price ? '+GST' : ''}</Typography>
        <Typography variant='body2' textAlign={'center'} fontSize={24}>{time ? time : ''}</Typography>
      </Stack>
      <Typography variant='caption'>{description}</Typography>
      <Stack pb={3} component='ul'>
        {features.map((feature, index) => <Typography key={`${title}~${index}`} component='li' variant='body2' ml={2} textAlign={'start'}>{feature}</Typography>)}
      </Stack>
    </Stack>

    {price > 0 && <Typography variant='caption' textAlign={'center'}>* non-refundable</Typography>}
    {payment && <Box sx={{ mt: 2, mx: 3, }}>
      <PayPalScriptProvider options={{
        intent: "subscription", vault: true,
        clientId: paymentTesting ?
          "ASe7CwcXeU3mq6Rui28N6wqIegyj4ZWZDkvKDzVOVN5rWUSse2Uwhnbs13ufB-Ep7_APmbZ16d49r_5n"
          : "AUacpFUc_BvZCjxhYi-8c96EIcyrgCLDrwF7hJbfx0-UEV-x_IOkQzU5O330dGmpq5jrQjYfWXt30sRw",
      }}>
        <PayPalButtons style={{ layout: "horizontal", color: 'blue', label: 'subscribe', tagline: false, }}
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: priceId,
              custom_id: `user-${user.id}`,
            });
          }}
          onApprove={onApprove} />
      </PayPalScriptProvider>
    </Box>}
    {!ribbon && !payment && <Button {...id !== priceId && {
      LinkComponent: Link,
      to: `/plans/${priceId}`
    }} sx={{
      mx: 3,
      px: 0
    }} variant={priceId === id ? 'contained' : 'outlined'} size='small'>
      Subscribe{priceId === id && 'd'}</Button>}

    {priceId === id && !user.paypalSubscription.cancel && <Button sx={{
      mx: 3,
      mt: 1,
      px: 0
    }} variant='outlined' size='small'
      onClick={onCancel}>
      Unsubscribe</Button>}
  </Paper>);
}
